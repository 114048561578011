<template>
  <ul class="o-accordion">
    <li
      v-for="item in items"
      class="
        o-accordion__item
        border border-secondary border-solid
        rounded-lg
        mb-2
      "
      :class="{ 'is-active': item.name === activeItem }"
      :key="item.name"
      @click="() => open(item.name)"
    >
      <h4
        class="
          o-accordion__item__title
          cursor-pointer
          bg-secondary
          text-white
          uppercase
          text-center
          overflow-hidden
          py-3
          pr-2
          pl-12
          rounded-lg
          relative
          z-10
          before:block
          before:h-5
          before:w-5
          before:absolute
          before:bg-no-repeat
          before:bg-contain
          before:bg-center
          before:z-20
          before:ml-2.5
          before:left-0
          before:transition-all
          before:duration-200
          before:ease-in
          after:w-10 after:h-full after:absolute after:inset-0
        "
        v-html="item.title"
      />
      <div
        class="
          o-accordion__item__content
          transition-all
          duration-200
          ease-in
          px-6
          text-primary
          overflow-hidden
          relative
          h-0
          max-h-0
        "
        :class="{
          'pb-6 pt-8 h-auto max-h-crazy md:py-8 lg:py-10 xl:py-14':
            item.name === activeItem,
        }"
      >
        <slot :name="item.name" />
      </div>
    </li>
  </ul>
</template>

<script>
import { ref } from "vue";

export default {
  name: "Accordion",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const activeItem = ref("");

    function open(itemName) {
      if (activeItem.value === itemName) {
        activeItem.value = "";
      } else {
        activeItem.value = itemName;
      }
    }

    return {
      activeItem,
      open,
    };
  },
};
</script>

<style lang="postcss">
.o-accordion {
  &__item {
    &.is-active {
      .o-accordion {
        &__item {
          &__title {
            &::before {
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }
      }
    }

    &__title {
      &::before {
        background-image: url("../../assets/arrow-down.svg");
        top: 50%;
        transform: translateY(-50%);
      }

      &::after {
        background-color: #79cdd0;
      }
    }
  }
}
</style>
